// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/:church?",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "helps",
          name: "Bible Helps",
          component: () => import("@/views/bible-help/Index.vue")
        },
        {
          path: "help/:entry",
          name: "Bible Help",
          component: () => import("@/views/bible-help/Entry.vue")
        },

        {
          path: "",
          name: "App",
          component: () => import("@/views/app/Index.vue")
        },
        {
          path: "*",
          name: "App",
          component: () => import("@/views/app/Index.vue")
        }
      ]
    }
  ]
});

export default router;
