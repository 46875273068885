import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins";

import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";

import FlagIcon from "vue-flag-icon";

Vue.config.productionTip = false;

// Info here: https://matteo-gabriele.gitbook.io/vue-gtag/v/master/auto-tracking
Vue.use(
  VueGtag,
  {
    appName: "biblehelp.app",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-DXMG80B6Z0" },
  },
  router
);

Vue.use(VueGtm, {
  id: "GTM-TMBN3F7",
});

Vue.use(FlagIcon);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
