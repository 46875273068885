import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    feedbackTitle: "Was this helpful for you?",
    helpsTitle: "What does the Bible say about",
  },
  es: {
    feedbackTitle: "Hasta espanol",
    helpsTitle: "Hasta espanol",
  },
};

export default new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "es", // set fallback locale
  messages, // set locale messages
});
